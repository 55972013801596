/* Estilo do cabeçalho do painel */
.header {
  display: flex;
  justify-content: space-between; /* Espaça o título e o botão */
  align-items: center; /* Centraliza verticalmente */
  padding: 10px 20px; /* Espaçamento ao redor */
  background-color: #f8f9fa; /* Cor de fundo do cabeçalho */
  border-bottom: 1px solid #dee2e6; /* Linha de separação inferior */
}


.sidebar {
  width: 250px; /* largura do sidebar */
  background-color: #f8f9fa; /* cor de fundo */
  padding: 15px; /* espaçamento */
  border-right: 1px solid #dee2e6; /* borda direita */
}

.sidebar h2 {
  font-size: 1.5em; /* tamanho do título */
  margin-bottom: 10px; /* espaçamento inferior */
}

.sidebar ul {
  list-style: none; /* remove bullets */
  padding: 0; /* remove padding */
}

.sidebar li {
  margin: 10px 0; /* espaçamento entre itens */
}

.sidebar li a {
  text-decoration: none; /* remove underline */
  color: #007bff; /* cor do texto */
  transition: color 0.3s, background-color 0.3s; /* transição suave */
  padding: 8px; /* padding para o link */
  border-radius: 4px; /* borda arredondada */
}

.sidebar li a:hover {
  background-color: #007bff; /* cor de fundo ao passar o mouse */
  color: white; /* cor do texto ao passar o mouse */
}

/* Estilo do Botão de Logout */
.logout-button {
  background-color: #dc3545; /* Cor de fundo vermelho */
  color: white; /* Cor do texto */
  border: none; /* Remove borda */
  border-radius: 5px; /* Bordas arredondadas */
  padding: 10px 15px; /* Tamanho normal do botão */
  cursor: pointer; /* Cursor de mão ao passar o mouse */
  transition: background-color 0.3s; /* Transição suave */
  flex-shrink: 0; /* Impede que o botão encolha */
  margin-bottom: 20px; /* Espaçamento abaixo do botão */
}

.logout-button:hover {
  background-color: #c82333; /* Cor de fundo mais escura ao passar o mouse */
}

/* Estilo do Modal */
.modal {
  display: none; /* Escondido por padrão */
  position: fixed; /* Fixo na tela */
  z-index: 1000; /* Exibir sobre outros elementos */
  left: 0;
  top: 0;
  width: 100%; /* Largura total */
  height: 100%; /* Altura total */
  overflow: auto; /* Adiciona rolagem se necessário */
  background-color: rgba(0, 0, 0, 0.5); /* Fundo semi-transparente */
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  margin: 15% auto; /* Centraliza verticalmente */
  width: 80%; /* Largura do modal */
  max-width: 500px; /* Largura máxima do modal */
}

.confirm-button,
.cancel-button {
  padding: 10px 20px;
  margin: 10px;
  border: none; /* Remove borda */
  border-radius: 5px; /* Bordas arredondadas */
  color: white; /* Cor do texto */
  cursor: pointer; /* Cursor de mão ao passar o mouse */
}

.confirm-button {
  background-color: #28a745; /* Verde para confirmar */
}

.cancel-button {
  background-color: #dc3545; /* Vermelho para cancelar */
}

.confirm-button:hover {
  background-color: #218838; /* Cor de fundo mais escura ao passar o mouse */
}

.cancel-button:hover {
  background-color: #c82333; /* Cor de fundo mais escura ao passar o mouse */
}

/* Tema Claro */
body.light-theme {
  background-color: #f8f9fa; /* Cor de fundo clara */
  color: #343a40; /* Cor do texto clara */
}

.sidebar.light-theme {
  background-color: white; /* Cor do menu lateral clara */
}

/* Tema Escuro */
body.dark-theme {
  background-color: #343a40; /* Cor de fundo escura */
  color: #f8f9fa; /* Cor do texto escura */
}

.sidebar.dark-theme {
  background-color: #495057; /* Cor do menu lateral escuro */
}

/* Estilos para botões no modo escuro */
body.dark-theme button {
  background-color: #007bff; /* Azul padrão */
  color: white;
}

body.dark-theme button:hover {
  background-color: #0056b3; /* Azul mais escuro ao passar o mouse */
}

.dashboard-admin {
  max-width: 1920px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
  min-height: 100vh; /* Garante que ocupe pelo menos a altura total da tela */
}

/* Estilo do menu lateral */
.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 250px;
  height: 100%;
  background-color: white; /* Cor de fundo do menu lateral */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
  padding: 20px;
  z-index: 1000; /* Para garantir que o menu lateral fique acima de outros elementos */
  overflow-y: auto; /* Permite rolagem se o conteúdo exceder a altura */
}

/* Estilo do cabeçalho do painel */
.dashboard-admin h1 {
  text-align: center;
  color: #343a40;
  margin-bottom: 20px;
  position: relative; /* Necessário para posicionar a contagem de alertas */
}

/* Estilo do ícone de sino */
.dashboard-admin h1 i {
  margin-left: 10px; /* Adiciona espaço entre o título e o ícone */
  font-size: 1.5em; /* Ajusta o tamanho do ícone */
  color: #ffcc00; /* Define a cor do ícone */
  transition: color 0.3s; /* Transição suave para o hover */
}

/* Efeito de hover no ícone de sino */
.dashboard-admin h1 i:hover {
  color: #ffd700; /* Muda a cor ao passar o mouse */
}

/* Contagem de alertas */
.alert-count {
  position: absolute;
  top: -5px; /* Ajusta a posição vertical da contagem */
  right: -10px; /* Ajusta a posição horizontal da contagem */
  background-color: red; /* Cor de fundo da contagem */
  color: white; /* Cor do texto */
  border-radius: 50%; /* Faz a contagem ser circular */
  padding: 5px 8px; /* Espaçamento interno */
  font-size: 0.9em; /* Tamanho da fonte */
  font-weight: bold; /* Negrito */
}

/* Estilo dos componentes no overview */
.dashboard-overview {
  display: flex;
  flex-wrap: wrap; /* Permite que os cartões se ajustem e envolvam */
  justify-content: space-between; /* Espaço igual entre os cartões */
  margin: 20px -5px; /* Aumentado para espaçamento superior e inferior */
}

.dashboard-overview > div {
  flex: 1 1 300px; /* O item pode crescer, encolher e deve ter uma largura mínima de 300px */
  margin: 15px 5px; /* Aumenta o espaçamento entre os cartões */
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 15px;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Transição suave */
}

/* Efeitos de hover para os cartões */
.dashboard-overview > div:hover {
  transform: translateY(-5px); /* Eleva o cartão ao passar o mouse */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Sombreamento mais intenso */
}

/* Cores para os cartões no dashboard */
.dashboard-overview > div:nth-child(1) {
  background-color: #007bff; /* Azul para o primeiro cartão */
  color: white;
}

.dashboard-overview > div:nth-child(2) {
  background-color: #28a745; /* Verde para o segundo cartão */
  color: white;
}

.dashboard-overview > div:nth-child(3) {
  background-color: #ffc107; /* Amarelo para o terceiro cartão */
  color: white;
}

.dashboard-overview > div:nth-child(4) {
  background-color: #2a0f3f; /* Vermelho para o quarto cartão */
  color: white;
}

.dashboard-overview > div:nth-child(5) {
  background-color: #17a2b8; /* Ciano para o quinto cartão */
  color: white;
}

.dashboard-overview > div:nth-child(6) {
  background-color: #6f42c1; /* Roxo para o sexto cartão */
  color: white;
}

/* Estilos para o gráfico */
.dashboard-overview .SalesChart {
  width: 100%;
  height: 400px; /* Tamanho agradável para o gráfico */
}


.product-data-table {
  overflow-x: auto; /* Adiciona rolagem horizontal se necessário */
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.table th,
.table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #dee2e6;
}

.table th {
  background-color: #007bff;
  color: white;
}

/* Efeito de hover nas linhas da tabela */
.table tr:hover {
  background-color: #f1f1f1;
}

/* Estilo da tabela */
.table {
  width: 100%;
  border-collapse: collapse; /* Remove espaços entre as bordas */
  margin-top: 20px; /* Espaçamento superior */
  overflow-x: auto; /* Permite rolagem horizontal em telas menores */
}

.table th,
.table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #dee2e6; /* Linha de separação entre linhas */
}

.table th {
  background-color: #007bff; /* Cor de fundo das cabeçalhos */
  color: white; /* Cor do texto */
}

/* Efeito de hover nas linhas da tabela */
.table tr:hover {
  background-color: #f1f1f1; /* Cor de fundo ao passar o mouse */
}

/* Estilos responsivos para telas menores */
@media (max-width: 768px) {
  .table {
    display: block; /* Torna a tabela um bloco para permitir rolagem */
    overflow-x: auto; /* Permite rolagem horizontal */
    white-space: nowrap; /* Impede a quebra de linha dentro das células */
  }

  .table th,
  .table td {
    padding: 10px; /* Espaçamento reduzido */
    font-size: 0.9em; /* Tamanho da fonte reduzido */
  }

  /* Opcional: transformar a tabela em um layout de cartões */
  .table tr {
    display: block; /* Cada linha se comporta como um bloco */
    margin-bottom: 15px; /* Espaçamento entre as linhas */
    border: 1px solid #dee2e6; /* Borda em torno de cada linha */
    border-radius: 5px; /* Bordas arredondadas */
    overflow: hidden; /* Para garantir que não transborde */
  }

  .table td {
    display: flex; /* Flex para alinhar o conteúdo */
    justify-content: space-between; /* Espaço entre o conteúdo */
    border-bottom: none; /* Remove a linha de separação em cada linha */
  }
}



/* Tabelas de log */
/* Estilos para o componente de histórico de atividades */
.activity-log-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.activity-log-title {
  text-align: center;
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.activity-log-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
}

.activity-log-table th, 
.activity-log-table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

/* Cabeçalho da tabela com uma cor de fundo azul e texto branco */
.activity-log-table th {
  background-color: #007BFF; /* Azul para o cabeçalho */
  color: white;
  font-weight: bold;
}

/* Linhas alternadas: linhas pares com um tom de cinza claro */
.activity-log-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Linhas alternadas: linhas ímpares com um tom de azul claro */
.activity-log-table tr:nth-child(odd) {
  background-color: #e0f7fa; /* Azul claro */
}

/* Cor ao passar o mouse sobre uma linha da tabela */
.activity-log-table tr:hover {
  background-color: #c8e6c9; /* Verde claro */
}

/* Cor do texto quando não há logs */
.no-logs {
  text-align: center;
  color: #999;
  font-style: italic;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.modal-actions {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.modal-actions button {
  padding: 10px 20px;
  cursor: pointer;
}

/* styles.css */

/* Estilos gerais para a lista de produtos */
.product-list {
  margin: 20px;
  font-family: Arial, sans-serif;
}

/* Estilo da tabela */
.product-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Estilo do cabeçalho da tabela */
.product-table thead {
  background-color: #4CAF50; /* Cor de fundo padrão do cabeçalho */
  color: white; /* Cor do texto do cabeçalho */
}

/* Estilo das células do cabeçalho */
.product-table th {
  padding: 12px; /* Espaçamento interno das células do cabeçalho */
  text-align: left; /* Alinhamento do texto */
  border: 1px solid #ddd; /* Borda das células do cabeçalho */
}

/* Cores específicas para cada coluna do cabeçalho */
.product-table th:nth-child(1) {
  background-color: #007BFF; /* Cor de fundo para Nome do Produto */
}

.product-table th:nth-child(2) {
  background-color: #FF5733; /* Cor de fundo para Preço */
}

/* Estilo das células da tabela */
.product-table td {
  padding: 12px; /* Espaçamento interno das células */
  text-align: left; /* Alinhamento do texto */
  border: 1px solid #ddd; /* Borda das células */
}

/* Efeito ao passar o mouse sobre as linhas */
.product-table tbody tr:hover {
  background-color: #f2f2f2; /* Cor de fundo ao passar o mouse */
}

/* Estilo para as colunas específicas */
.product-table tbody td:nth-child(1) {
  background-color: #e7f7fe; /* Cor de fundo da coluna 1 (Nome do Produto) */
}

.product-table tbody td:nth-child(2) {
  background-color: #fff8e1; /* Cor de fundo da coluna 2 (Preço) */
}

/* Estilo para células com preços */
.product-table td {
  color: #333; /* Cor do texto para as células de preço */
  font-weight: bold; /* Deixar o texto em negrito */
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.modal-actions {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.modal-actions button {
  padding: 10px 20px;
  cursor: pointer;
}

/* relatorios */


.export-reports {
  text-align: center;
}

.btn-export {
  background-color: red;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.btn-export:hover {
  background-color: darkred;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  width: 400px;
}

.modal-options {
  margin: 10px 0;
}

.filter-options {
  margin-top: 20px;
}

.filter-options label {
  display: block;
  margin-bottom: 10px;
}

.btn-confirm {
  background-color: green;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 5px;
}

.btn-cancel {
  background-color: gray;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 5px;
  margin-left: 10px;
}

.btn-confirm:hover {
  background-color: darkgreen;
}

.btn-cancel:hover {
  background-color: darkgray;
}

.modal-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.radio-option {
  display: flex;
  align-items: center;
  gap: 5px;
}

.filter-options label {
  margin-bottom: 15px;
}

.export-reports .btn-export {
  margin: 15px 10px;
}

/* admin */
.dashboard-admin {
  display: flex;
  flex-direction: column;
  margin: 10px; /* Ajuste o espaçamento geral */
}

.menu-button {
  margin-bottom: 10px; /* Espaço abaixo do botão de menu */
}

.logout-button {
  margin-top: 10px; /* Espaço acima do botão de logout */
  margin-left: 10px; /* Ajuste a margem à esquerda para mais distância do lado */
}

.sidebar {
  margin-left: 10px; /* Ajuste o espaçamento da sidebar em relação ao conteúdo principal */
  padding-left: 20px;
}

/* employee select */

/* Estilização básica para o select */
.styled-select {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

/* Adicionando efeitos de hover e foco */
.styled-select:focus {
  border-color: #4CAF50;
  outline: none;
}

/* Estilizando o botão de submit */
button[type="submit"] {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin: 10px 0;
}

button[type="submit"]:hover {
  background-color: #45a049;
}

/* Estilizando o botão de fechar */
button[type="button"] {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

button[type="button"]:hover {
  background-color: #e53935;
}

/* Estilo geral para o select */
select {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  color: #333;
  outline: none;
  transition: border-color 0.3s ease;
}

/* Efeito de foco no select */
select:focus {
  border-color: #007BFF;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Estilo para a seta do select */
select::-ms-expand {
  display: none;
}

/* Para navegadores que suportam pseudo-elementos */
select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 20 20"%3E%3Cpath fill="%23333" d="M6 8l4 4 4-4H6z"/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px 10px;
}

/* Estilo responsivo */
@media (max-width: 768px) {
  select {
      font-size: 14px;
      padding: 8px;
  }
}



/* css formulario */

/* Botões */
button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  margin-right: 5px;
  transition: background-color 0.3s ease;
}

button:hover {
  opacity: 0.9;
}

button:first-of-type {
  background-color: #007bff;
  color: white;
}

button:first-of-type:hover {
  background-color: #0056b3;
}

button:last-of-type {
  background-color: #dc3545;
  color: white;
}

button:last-of-type:hover {
  background-color: #a71d2a;
}

/* Formulário de edição */
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-bottom: 20px;
}

form label {
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
  display: block;
}

form input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

form input:focus {
  border-color: #4caf50;
  outline: none;
}

/* Botões de formulário */
form + button {
  margin-top: 20px;
  background-color: #4caf50;
  color: white;
}

form + button:hover {
  background-color: #388e3c;
}

