/* Reset básico */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Estilos gerais */
  body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f9;
    color: #333;
  }

  .cart {
    background-color: #e0f7fa; /* Cor de fundo escolhida */
    color: black; /* Cor do texto */
    border: 1px solid #ccc; /* Borda do carrinho */
    border-radius: 8px; /* Cantos arredondados */
    padding: 20px; /* Espaçamento interno */
    margin: 20px; /* Margem externa */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Sombra do carrinho */
  }
  
  
  .cart h3 {
    display: flex; /* Usado para alinhar o ícone e o texto */
    align-items: center; /* Centraliza verticalmente */
    font-size: 1.5em; /* Tamanho da fonte do título */
    color: #333; /* Cor do texto do título */
  }
  
  .cart h3 .fa-shopping-cart {
    margin-right: 10px; /* Espaçamento à direita do ícone */
  }
  
  .cart p {
    color: #666; /* Cor do texto de mensagens */
    font-size: 1em; /* Tamanho da fonte das mensagens */
  }
  
  .cart ul {
    list-style-type: none; /* Remove marcadores da lista */
    padding: 0; /* Remove espaçamento interno da lista */
    margin: 10px 0; /* Margem acima e abaixo da lista */
  }
  
  .cart li {
    display: flex; /* Usado para alinhar texto e botão */
    justify-content: space-between; /* Espaça os itens da lista */
    padding: 10px; /* Espaçamento interno do item */
    border-bottom: 1px solid #eee; /* Borda inferior dos itens */
  }
  
  .cart li:last-child {
    border-bottom: none; /* Remove a borda do último item */
  }
  
  .cart button {
    background-color: #ff4d4d; /* Cor de fundo do botão */
    color: white; /* Cor do texto do botão */
    border: none; /* Remove bordas do botão */
    border-radius: 5px; /* Cantos arredondados do botão */
    padding: 5px 10px; /* Espaçamento interno do botão */
    cursor: pointer; /* Muda o cursor ao passar o mouse sobre o botão */
    transition: background-color 0.3s; /* Transição suave da cor de fundo */
  }
  
  .cart button:hover {
    background-color: #ff1a1a; /* Cor do botão ao passar o mouse */
  }
  
  /* Estilo para o campo de entrada */
  .cart input[type="text"] {
    border: 1px solid #ccc; /* Borda do campo de entrada */
    border-radius: 5px; /* Cantos arredondados do campo */
    padding: 8px; /* Espaçamento interno do campo */
    margin-right: 10px; /* Espaçamento à direita do campo */
    flex: 1; /* Faz o campo ocupar o espaço restante */
  }
  
  /* Estilo para o botão de adicionar */
  .cart button[type="button"] {
    background-color: #28a745; /* Cor de fundo do botão de adicionar */
  }
  
  .cart button[type="button"]:hover {
    background-color: #218838; /* Cor ao passar o mouse */
  }
  
  
  .confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .confirmation-modal {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .confirm-button {
    background: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
  }
  
  .cancel-button {
    background: #f44336;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
  }
  

  .employee-dashboard {
    max-width: 1920px;
    margin: 0 auto;
    padding: 20px;
  }

  .dashboard-content input[type="text"] {
    width: 100%;               /* Define a largura do campo como 100% do contêiner pai */
    max-width: 500px;         /* Define uma largura máxima para evitar que o campo fique muito largo */
    padding: 10px;            /* Adiciona padding para aumentar o espaço interno */
    font-size: 16px;          /* Aumenta o tamanho da fonte para melhor legibilidade */
    border: 1px solid #ccc;   /* Define uma borda clara para o campo */
    border-radius: 4px;       /* Adiciona bordas arredondadas ao campo */
    margin-bottom: 20px;      /* Adiciona espaço abaixo do campo */
}

.dashboard-content input[type="number"] {
    width: 100%;               /* Define a largura do campo como 100% do contêiner pai */
    max-width: 500px;         /* Define uma largura máxima para evitar que o campo fique muito largo */
    padding: 10px;            /* Adiciona padding para aumentar o espaço interno */
    font-size: 16px;          /* Aumenta o tamanho da fonte para melhor legibilidade */
    border: 1px solid #ccc;   /* Define uma borda clara para o campo */
    border-radius: 4px;       /* Adiciona bordas arredondadas ao campo */
    margin-bottom: 20px;      /* Adiciona espaço abaixo do campo */
}

  
  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #4CAF50;
    padding: 15px;
    border-radius: 8px;
    color: #fff;
    flex-wrap: wrap;
  }
  
  .dashboard-header h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .header-icons {
    display: flex;
    align-items: center;
  }
  
  .header-icons > * {
    margin-left: 20px;
  }
  
  
  button {
    background-color: #ff4757;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #ff6b81;
  }
  
  .dashboard-content {
    margin-top: 20px;
  }
  
  h2 {
    margin: 20px 0 10px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  table, th, td {
    border: 1px solid #ddd;
  }
  
  th, td {
    padding: 12px;
    text-align: left;
  }
  
  th {
    background-color: #4CAF50;
    color: white;
  }
  
  input[type="text"], input[type="number"] {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .loading-spinner, .error-message {
    text-align: center;
    margin: 20px 0;
  }
  
  .loading-spinner i {
    font-size: 24px;
    color: #4CAF50;
  }
  
  .error-message p {
    color: red;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
  }
  
  .confirm-button, .cancel-button {
    padding: 10px 20px;
    margin: 10px;
  }
  
  .confirm-button {
    background-color: #28a745;
  }
  
  .cancel-button {
    background-color: #dc3545;
  }
  
  /* Responsividade */
  @media (max-width: 768px) {
    .dashboard-header {
      flex-direction: column;
      text-align: center;
    }
  
    .dashboard-header h1 {
      font-size: 20px;
    }
  
    table, th, td {
      font-size: 14px;
    }
  
    input[type="text"], input[type="number"] {
      font-size: 14px;
    }
  
    button {
      padding: 8px 15px;
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .dashboard-header {
      flex-direction: column;
    }
  
    table, th, td {
      font-size: 12px;
    }
  
    button {
      padding: 6px 10px;
      font-size: 12px;
    }
  
    .modal-content {
      width: 90%;
      padding: 10px;
    }
  }


  .dashboard-content h2 {
    font-size: 1.5em;
    margin: 20px 0 10px;
    color: #333;
    text-transform: uppercase;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  th, td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  th {
    background-color: #f4f4f4;
    color: #333;
    font-weight: bold;
  }
  
  tbody tr:hover {
    background-color: #f1f1f1; /* Altera a cor da linha ao passar o mouse */
  }
  
  tbody tr:nth-child(even) {
    background-color: #f9f9f9; /* Cor para linhas pares */
  }
  
  tbody tr:nth-child(odd) {
    background-color: #ffffff; /* Cor para linhas ímpares */
  }
  
  .error-message {
    color: red;
    font-weight: bold;
  }
  
  .loading-spinner {
    text-align: center;
    margin: 20px 0;
  }
  
  .confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .confirmation-modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .confirm-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cancel-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cancel-button:hover, .confirm-button:hover {
    opacity: 0.9; /* Efeito hover para os botões */
  }

  .dashboard-container {
    display: flex;
  }
  
  .sidebar {
    width: 200px;
    background-color: #f4f4f4;
    padding: 15px;
    border-right: 1px solid #ddd;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar li {
    margin-bottom: 15px;
    cursor: pointer;
  }
  
  .sidebar li:hover {
    background-color: #ddd;
    padding-left: 5px;
  }
  
  .dashboard-content {
    padding: 20px;
    flex-grow: 1;
  }
  
  .loading-spinner, .error-message {
    text-align: center;
  }
  
  h2 {
    margin-top: 20px;
  }
  
  .tabs button {
    padding: 10px;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .tabs .active {
    background-color: #007bff;
    color: white;
  }
  
  .dashboard-content {
    margin-top: 20px;
  }
  
  .tab-content {
    display: none;
  }
  
  .tab-content.active {
    display: block;
  }
  






  /* teste */

  .sales-container {
    background-color: #f9f9f9; /* Cor de fundo leve */
    border: 1px solid #ccc; /* Borda ao redor da seção */
    border-radius: 8px; /* Cantos arredondados */
    padding: 20px; /* Espaçamento interno */
    margin: 20px; /* Margem externa */
  }
  
  .section-title {
    font-size: 1.5em; /* Tamanho da fonte do título */
    color: #333; /* Cor do texto do título */
    margin-bottom: 15px; /* Espaçamento abaixo do título */
  }
  
  .total-section {
    margin-top: 20px; /* Espaço acima da seção de total */
  }
  
  .total-amount {
    font-size: 1.2em; /* Aumenta o tamanho da fonte do total */
    color: #333; /* Cor do texto */
    margin-bottom: 15px; /* Espaçamento abaixo do total */
  }
  
  .payment-input {
    width: 100%; /* Ocupa a largura total */
    padding: 10px; /* Espaçamento interno */
    border: 1px solid #ccc; /* Borda do campo */
    border-radius: 4px; /* Cantos arredondados */
    margin-bottom: 15px; /* Espaçamento abaixo do campo */
  }
  
  .finalize-button {
    background-color: #007bff; /* Cor de fundo do botão */
    color: white; /* Cor do texto do botão */
    border: none; /* Remove a borda padrão */
    border-radius: 4px; /* Cantos arredondados do botão */
    padding: 10px 15px; /* Espaçamento interno do botão */
    cursor: pointer; /* Cursor de ponteiro ao passar sobre o botão */
    font-size: 1em; /* Tamanho da fonte do botão */
  }
  
  .finalize-button:hover {
    background-color: #0056b3; /* Cor do botão ao passar o mouse */
  }
  
  .sales-summary {
    margin-top: 20px; /* Espaço acima da seção de resumo de vendas */
  }
  


  /* Estilos da tabela de Estoque */
.stock-table {
  width: 100%; /* Largura total da tabela */
  border-collapse: collapse; /* Remove o espaço entre as bordas das células */
  margin-top: 20px; /* Espaço acima da tabela */
}

/* Estilo para cabeçalhos da tabela */
th {
  background-color: #007bff; /* Cor de fundo do cabeçalho (azul) */
  color: white; /* Cor do texto do cabeçalho */
  font-weight: bold; /* Negrito para o texto do cabeçalho */
  padding: 15px; /* Espaçamento interno do cabeçalho */
  text-align: left; /* Alinhamento do texto à esquerda */
}

/* Estilo para os cabeçalhos das colunas com cores diferentes */
th:nth-child(1) {
  color: #ff5733; /* Cor do texto para o cabeçalho ID */
}

th:nth-child(2) {
  color: #33c3ff; /* Cor do texto para o cabeçalho Nome */
}

th:nth-child(3) {
  color: #33c3ff; /* Cor do texto para o cabeçalho Qtd */
}

th:nth-child(4) {
  color: #33c3ff; /* Cor do texto para o cabeçalho Valor de venda */
}

th:nth-child(5) {
  color: #33c3ff; /* Cor do texto para o cabeçalho Disponível */
}

/* Estilo para as células da tabela */
td {
  border: 1px solid #ddd; /* Borda leve nas células */
  padding: 12px; /* Espaçamento interno das células */
  text-align: left; /* Alinhamento do texto à esquerda */
}

/* Estilos específicos para a tabela de Estoque */
.stock-table tbody tr {
  background-color: #f2f2f2; /* Cor de fundo padrão das linhas do corpo da tabela */
}

.stock-table tbody tr:hover {
  background-color: #e0f7fa; /* Cor da linha ao passar o mouse */
}

.stock-table tbody tr:nth-child(even) {
  background-color: #ffffff; /* Cor para linhas pares */
}

.stock-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9; /* Cor para linhas ímpares */
}

/* Estilo para mensagens de erro (se necessário) */
.error-message {
  color: red; /* Cor vermelha para mensagens de erro */
  font-weight: bold; /* Negrito para mensagens de erro */
}
  