/* Estilos gerais */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 20px;
  background-color: #f4f4f4;
}

h2 {
  text-align: center;
}

/* Formulário */
form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto 20px auto;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

form input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

form button {
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

form button:hover {
  background-color: #218838;
}

/* Tabela */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
}

/* Estilos para botões da tabela */
table button {
  padding: 5px 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

table button:hover {
  background-color: #c82333;
}

/* Responsividade */
@media (max-width: 768px) {
  form {
    max-width: 100%; /* Ajusta o tamanho do formulário */
    padding: 10px;
  }

  th, td {
    font-size: 14px; /* Reduz o tamanho da fonte em telas menores */
    padding: 8px; /* Ajusta o padding */
  }

  form input {
    font-size: 14px; /* Ajusta o tamanho da fonte dos inputs */
  }

  form button {
    font-size: 14px; /* Ajusta o tamanho da fonte do botão */
  }

  table {
    font-size: 14px; /* Ajusta o tamanho da fonte da tabela */
  }
}

@media (max-width: 480px) {
  form {
    padding: 5px; /* Menor padding para telas muito pequenas */
  }

  th, td {
    font-size: 12px; /* Reduz ainda mais o tamanho da fonte */
    padding: 5px; /* Ajusta o padding */
  }

  form input {
    font-size: 12px; /* Ajusta o tamanho da fonte dos inputs */
  }

  form button {
    font-size: 12px; /* Ajusta o tamanho da fonte do botão */
  }

  table {
    font-size: 12px; /* Ajusta o tamanho da fonte da tabela */
  }
}

/* Estilos do Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Garantir que o modal fique acima de outros elementos */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-buttons {
  margin-top: 20px;
}

.modal-buttons button {
  margin: 0 10px;
}

/* Ajustes para manter o layout alinhado */
.container {
  max-width: 1200px; /* Largura máxima do contêiner */
  margin: 0 auto; /* Centraliza o contêiner */
  padding: 0 20px; /* Adiciona espaço nas laterais */
}

.table-container {
  overflow-x: auto; /* Permite rolagem horizontal se a tabela for muito larga */
}
