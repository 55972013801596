/* src/components/Notifications.css */

.notifications-container {
    position: relative;
    display: inline-block;
  }
  
  .bell-icon {
    cursor: pointer;
    font-size: 24px;
    position: relative;
  }
  
  .notification-count {
    position: absolute;
    top: -5px;
    right: -10px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 2px 5px;
    font-size: 12px;
  }
  
  .notification-list {
    position: absolute;
    top: 30px;
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 250px;
    z-index: 1000;
  }
  
  .notification-item {
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
  }
  
  .notification-item:hover {
    background-color: #f5f5f5;
  }
  
  .unread {
    font-weight: bold;
  }
  
  .read {
    color: gray;
  }
  